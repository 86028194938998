<template>
  <div class="content" ref="content">
    <div class="cardInfo">
      <div class="topBox">
        <div class="left">ICCID：{{ courseInfo.iccid }}</div>
        <button class="right_copy" :data-clipboard-text="courseInfo.iccid">
          点击复制
        </button>
      </div>
      <!-- 广东移动 显示  物联网卡号后四位-->
			<!-- 全国移动 显示 物联网卡号-->
			<div class="topBox" v-if="courseInfo.type == 2 || courseInfo.type == 3 ">
        <div class="left">
          物联网卡号：{{courseInfo.sim}}
        </div>
        <button
          class="right_copy"
          :data-clipboard-text="courseInfo.sim"
        >
          点击复制
        </button>
      </div>
      <div class="bottomBox">
        {{ `强烈建议，先将${courseInfo.type == 2 ? "两个" : ""}`
        }}{{ `卡号复制到手机备忘录，以便实名的时候需要用到！！！` }}<br />
        {{ `复制后强烈建议查看教程后，点击【我已知晓并进入实名】` }}
      </div>
    </div>
    <div>
      <img :src="operatorCourse" alt="" style="width:100%" />
    </div>
    <button class="btn" @click="toRealName" v-show="btnVisable">
      我已知晓并进入实名
    </button>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, computed, onMounted, onUnmounted } from "vue";
import { getStore } from "@/utils/store";
import { urlAddParams } from '_UTIL_/utils.js'
import { realNameUrl } from "_API_/api.services";
import { useRoute } from "vue-router";
import ClipboardJS from "clipboard";
export default {
  setup() {
    const state = reactive({
      courseInfo: {},
			realNameLink:"",
			btnVisable:false
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    const { $tool: tool } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    Object.assign(state.courseInfo, route.query)
    try {
      state.courseInfo.sim = state.courseInfo.sim.replace('/','')
      if(state.courseInfo.type == 2){
        state.courseInfo.sim = state.courseInfo.sim.substr(-4, 4)
      }
    } catch (error) {
      console.log(error)
    }
    const methods = {
      // 去实名
      toRealName() {
				// 有实名链接
				if(state.realNameLink){
					return location.replace(urlAddParams(state.realNameLink,`card=${state.courseInfo.iccid}`))
				}
				realNameUrl(state.courseInfo.iccid).then(({data:res})=>{{
					// 等待审核
          if (res.code == 0 && res.data.realNameStatus == 1) {
            router.push({
              name: "realName-wait"
            });
            return;
          }
          // 若已经三要素实名，有通道链接 则可再次跳转通道链接
          if (
            res.code == 0 &&
            res.data.realNameStatus == 2 &&
            res.data.realNameUrl
          ) {
            return location.replace(urlAddParams(res.data.realNameUrl,`card=${res.data.iccid}`))
          }
          // 若已经三要素实名，没通道链接 则提示已实名
          if (res.code == 0 && res.data.realNameStatus == 2) {
            return tool.alert({
              title: "提示",
              msg: "用户已实名，无需再次实名"
            });
          }
					// 实名驳回
          if (
            res.data.hasOwnProperty("errorMessage") &&
            res.data.errorMessage !== null
          ) {
            router.push({
              name: "realName-reject",
              query: {
                msg: res.data.errorMessage
              }
            });
            return;
          }
					if (res.code != 0) {
            return tool.alert({
              title: "提示",
              msg: res.msg
            });
          }
					location.replace(urlAddParams(res.data.realNameUrl,`card=${res.data.iccid}`))
        }})
      },
			/**
			 * @funcHandle handleScroll-滚动条句柄
			 * @params htmlHeight-当前页面的总高度  clientHeight-当前页面在浏览器中的可视高度  scrollTop-当前浏览器滚动条到top位置的高度
			 *
			 */
			handleScroll(){
				let htmlHeight= ctx.$refs['content'].scrollHeight
				let totalHeight = ctx.$refs['content'].clientHeight + ctx.$refs['content'].scrollTop + 2 //小米系列机型适配处理
				if(totalHeight > htmlHeight){
					state.btnVisable = true
				}else{
					state.btnVisable = false
				}
			}
    };
    //获取运营商对应的实名教程 1、电信  2、移动 3、联通 4、聚合卡
    const operatorCourse = computed(() => {
      switch (Number(state.courseInfo.type)) {
        case 1:
          return require("../../assets/image/personal/telecomCourse.png");
        case 2:
          return require("../../assets/image/personal/guangDongMobile.png");
        case 3:
          return require("../../assets/image/personal/nationalMobile.png");
        case 4:
          return require("../../assets/image/personal/unicomCourse.png");
        default:
          return require("../../assets/image/personal/unicomCourse.png");
      }
    });
    var clipboard = new ClipboardJS(".right_copy");
    clipboard.on("success", e => {
      tool.toast({
        msg: "卡号复制成功",
        duration: 2500
      });
      e.clearSelection();
    });
    clipboard.on("error", e => {
      tool.alert({
        title: "提示",
        msg: `请先长按卡号复制\n${state.courseInfo.iccid}\n`,
        confirmButtonText: "已复制"
      });
    });
		onMounted(()=>{
			state.realNameLink = getStore({name:"realNameUrlInCourse",	type: "session"})
			window.addEventListener('scroll', ctx.handleScroll, true);
		})
		onUnmounted(()=>{
			window.removeEventListener("scroll", ctx.handleScroll, true);
		})
    return {
      ...toRefs(state),
      ...methods,
      operatorCourse,
      tool
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.content {
	height: 100vh;
	overflow-y: auto;
  .cardInfo {
    display: flex;
    flex-direction: column;
    .topBox {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      .left {
        font-size: 32px;
      }
      .right_copy {
        width: 150px;
        height: 48px;
        border-radius: 12px;
        border: none;
        text-align: center;
        line-height: 40px;
        font-size: 28px;
        box-shadow: 0 1px 1px 1px #d2d2d2;
        color: #fff;
        @include background_color("background_color1");
      }
    }
    .bottomBox {
      color: red;
      font-size: 24px;
      padding: 10px;
    }
  }
  .btn {
    width: 70%;
    height: 88px;
    border-radius: 12px;
    border: none;
    text-align: center;
    line-height: 40px;
    font-size: 28px;
    position: fixed;
    bottom: 10px;
    left: 15%;
    box-shadow: 0 1px 1px 1px #d2d2d2;
    color: #fff;
    @include background_color("background_color1");
  }
}
</style>
